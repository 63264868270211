<template>
    <div class="connexion">
        <div class="entete">
            <div class="fil-ariane">
                <router-link to="/">Accueil</router-link>
                <span> > </span>
                <router-link to="/connexion">Connexion</router-link>
            </div>
            <h2 v-if="modeInscript" class="titre">Inscription</h2>
            <h2 v-else class="titre">Identifiez-Vous</h2>
        </div>
        <div class="contenu">
            <template v-if="modeInscript">
                <v-form @submit.prevent="inscription" ref="register" v-model="formValid" class="carte-arrondie inscription">
                    <v-text-field filled solo hide-details="auto" :rules="rules.nom" label="NOM Prénom" v-model="inscr.name" class="input"></v-text-field>
                    <v-text-field filled solo hide-details="auto" :rules="rules.email" label="Adresse e-mail" v-model="inscr.email" class="input"></v-text-field>
                    <v-text-field filled solo hide-details="auto" :rules="rules.password" label="Mot de passe" v-model="inscr.password" class="input" type="password" autocomplete="new-password"></v-text-field>
                    <v-text-field filled solo hide-details="auto" :rules="rules.confirm" label="Confirmation du mot de passe" v-model="inscr.confirmPassword" class="input" type="password" autocomplete="password"></v-text-field>
                    <v-checkbox hide-details label="Je souhaite m'inscrire à la newsletter"></v-checkbox>
                    <v-checkbox :rules="rules.cgu" v-model="inscr.cgu" label="Je reconnais avoir lu et accepté les conditions générales d'utilisation et de vente du site"></v-checkbox>
                    <div class="actions flex">
                        <v-btn @click.stop.prevent="modeInscript = false" class="rosevide">Retour</v-btn>
                        <v-spacer></v-spacer>
                        <btn-theme type="submit" @:disabled="!formValid">S'inscrire</btn-theme>
                    </div>
                </v-form>
            </template>
            <template v-else>
                <v-form class="carte-arrondie">
                    <h3>Pas encore inscrit</h3>
                    <div>Saisissez votre adresse mail pour créer un compte</div>
                    <v-text-field filled solo label="Adresse e-mail" v-model="inscr.email" hide-details class="input"></v-text-field>
                    <v-spacer></v-spacer>
                    <div class="actions">
                        <v-btn class="vide" type="submit" @click.stop.prevent="toggleInscription">Créez votre compte</v-btn>
                    </div>
                </v-form>
                <v-form v-if="activateAccount" @submit.prevent="newConfirmation" class="carte-arrondie">
                    <h3>Compte à valider</h3>
                    <div>
                        Votre compte nécessite une validation par email.<br/>
                        Vérifiez vos emails ou demandez un nouvel envoi ici.
                    </div>
                    <v-text-field filled solo label="Adresse e-mail" v-model="logEmail" hide-details class="input" autocomplete="username"></v-text-field>
                    <div class="actions">
                        <v-btn class="plein" type="submit">Envoyer</v-btn>
                        <v-btn class="vide" @click="activateAccount = false">Retour</v-btn>
                    </div>
                </v-form>
                <v-form v-else ref="login" @submit.prevent="connexion" class="carte-arrondie">
                    <h3>Déjà inscrit ?</h3>
                    <v-text-field filled solo ref="email" validate-on-blur :rules="rules.email" hide-details="auto" label="Adresse e-mail" v-model="logEmail" class="input" autocomplete="username"></v-text-field>
                    <v-text-field filled solo label="Mot de passe" validate-on-blur :rules="rules.password" v-model="logPass" hide-details="auto" class="input" type="password" autocomplete="current-password"></v-text-field>
                    <div v-if="badCredentials" class="badcred">Mot de passe incorrect</div>
                    <div class="actions">
                        <v-btn class="plein" type="submit">Connexion</v-btn>
                        <v-btn class="vide" @click.prevent="envoiLien">Mot de passe oublié</v-btn>
                    </div>
                </v-form>
            </template>
        </div>
    </div>
</template>

<script>
import Api from "../api/api";
import {mapActions} from "vuex";
import BtnTheme from "../components/theme/BtnTheme";

export default {
    name: "Connexion",
    components: {BtnTheme},
    data: () => ({
        logEmail: "",
        logPass: "",

        inscr: {
            email: "",
            name: "",
            password: "",
            confirmPassword: ""
        },

        formValid: true,
        rules: {
            nom : [
                v => !!v || "Le champ Nom/Prénom est obligatoire"
            ],
            email : [
                v => !!v || "Le champ e-mail est obligatoire",
                v => /.+@.+/.test(v) || 'L\'e-mail doit être valide',
                v => /.+@.+\..+/.test(v) || 'L\'e-mail doit être valide'
            ],
            password : [
                v => !!v || "Le champ Mot de passe est obligatoire"
            ],
            confirm : [
                v => !!v || "Le champ de confirmation du Mot de passe est obligatoire"
            ],
            cgu: [
                v => !!v || "L'acceptation des Conditions générales d'utilisation et de vente est obligatoire"
            ]
        },
        badCredentials: false,
        modeInscript: false,
        activateAccount: false,
        erreurEmail: false,
        envoiLienURI: process.env.VUE_APP_API_URL + '/lien',
        envoiNewConfirmURI: process.env.VUE_APP_API_URL + '/newconfirm',
        inscriptionURI: process.env.VUE_APP_API_URL + '/register',
    }),
    methods: {
        ...mapActions([
            'login'
        ]),
        newConfirmation() {
            let data = new FormData();
            data.append('email', this.logEmail);
            Api.post(this.envoiNewConfirmURI, data).then(res => {
                this.$store.commit('addSnackMessage', {msg: res.message, color:'success', duration:10e3});
                this.activateAccount = false;
            }).catch(res => {
                let message = Api.getMessageFrom(res);
                if (message === 'emails.thottled') message = 'Trop de tentative d\'envoi du lien';
                this.$store.commit('addSnackMessage', {msg: message});
            });
        },
        connexion() {
            if (!this.$refs.login.validate()) return;
            this.badCredentials = false;
            let datas = {
                email: this.logEmail,
                password: this.logPass
            }
            if (this.portailPredefini) datas.portail_id = this.portailPredefini;
            this.login(datas)
                .catch(err => {
                    let message = Api.getMessageFrom(err);
                    if (message === 'require activation') this.activateAccount = true;
                    else                                  this.badCredentials = true;
                });
        },
        toggleInscription() {
            this.modeInscript = true;
        },
        inscription () {
            if (!this.$refs.register.validate()) return;

            this.badCredentials = false;
            let data = new FormData();
            data.append('name', this.inscr.name);
            data.append('email', this.inscr.email);
            data.append('password', this.inscr.password);
            data.append('password_confirmation', this.inscr.confirmPassword);
            Api.post(this.inscriptionURI, data).then(contenu => {
                if (contenu === 'success') contenu = 'Inscription confirmée, merci d\'activer votre compte via le lien transmis par email';
                this.logEmail = this.inscr.email;
                this.logPass  = this.inscr.password;
                this.modeInscript = false;
                this.$store.commit('addSnackMessage', {msg: contenu, color:'success', duration:10e3});
            }).catch(res => {
                let message = Api.getMessageFrom(res);
                if (message === 'passwords.thottled') message = 'Trop de tentative d\'envoi du lien';
                this.$store.commit('addSnackMessage', {msg: message});
            });
        },
        envoiLien() {
            this.erreurEmail = false;
            let valide = this.$refs.email.validate();
            if (!valide) {
                this.$refs.email.focus();
                this.$refs.email.blur();
            } else {
                let data = new FormData();
                data.append('email', this.logEmail);
                Api.post(this.envoiLienURI, data).then(res => {
                    this.$store.commit('addSnackMessage', {msg: res, color:'success', duration:10e3});
                }).catch(res => {
                    let message = Api.getMessageFrom(res);
                    if (message === 'passwords.throttled') message = 'Trop de tentative d\'envoi du lien';
                    this.$store.commit('addSnackMessage', {msg: message});
                });
            }
        }
    }
}
</script>

<style scoped lang="scss">
.connexion {
    width: 100%;
    background-color:#F5F5F5;

    display: flex;
    flex-direction: column;
    & > * {
        margin: 0 auto;
        width:100%;
        padding: 12px;
        display: flex;
        max-width:1280px;
    }
    & .entete {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        & .fil-ariane {
            padding-left: 10px;
            width: 100%;
            text-align: left;

            & > a, span {
                text-decoration: none;
                font: normal normal normal 15px/20px Nunito;
                color: #535353;
            }
        }
        & .titre {
            font: normal normal bold 35px/47px Nunito;
        }
    }
    .contenu {
        display:flex;
        flex-direction: row;
        justify-content:space-between;
        & .carte-arrondie.connexion {

        }
        & .carte-arrondie {
            &.inscription {
                max-width:800px;
                margin: 0 auto;
                display:flex;
                width:100%;
                flex-wrap: wrap;
                & > .v-text-field {
                    padding-left: 20px;
                    padding-right:20px;
                    flex:50%;
                }
            }
            & .badcred {
                margin-left:10px;
                color:red;
                font-weight: bold;
            }
            & > div > .vide, .plein {
                font: normal normal normal 20px/27px Nunito;
                letter-spacing: 0;
            }
            & > h3 {
                font: normal normal bold 32px/44px Nunito;
            }
            & > div:not(.v-input--checkbox) {
                padding-top:10px;
            }
            & > .input, .password {
                border-radius:16px;
            }
            & > .actions {
                display:flex;
                flex-direction:row;
                justify-content: space-between;
                & > .vide {
                    text-transform: none;
                    color:#00D5FF;
                    border: #00D5FF solid 2px;
                    background-color:white;
                }
                & > .plein {
                    color:white;
                    text-transform: none;
                    background-color:#00D5FF;
                }
                & > .rosevide {
                    color: #FF00E5;
                    border: #FF00E5 solid 2px;
                    background-color:white;
                }
            }
        }
    }
}
</style>