<template>
    <div class="compte">
        <div class="entete">
            <div class="fil-ariane">
                <router-link to="/">Accueil</router-link>
                <span> > </span>
                <router-link to="/compte">Mon compte</router-link>
            </div>
            <h1 class="titre">Mon compte</h1>
        </div>
        <div class="carte-arrondie profil">
            <div class="infos">
                <div class="ligne">
                    {{ user.name }}
                </div>
                <div class="ligne" v-if="user.login">
                    Identifiant : {{ user.login }}
                </div>
                <div class="ligne">
                    Email de contact : {{ user.email }}
                </div>
                <div class="ligne" v-if="avoir > 0">
                    Bon d'achat : {{ formatMonetaire(avoir) }}
                </div>
                <div class="ligne">
                    Inscription newsletter
                    <v-switch inset color="#24d2fd" class="d-inline-block"></v-switch>
                </div>
                <router-link to="/compte/suppression" custom v-slot="{ navigate }">
                    <btn-theme class="red" type="button" @click="navigate"><v-icon class="mr-2">{{ mdiTrashCan }}</v-icon>Effacer mon compte</btn-theme>
                </router-link>
<!--                <router-link to="/compte/edition" custom v-slot="{href, route, navigate}">
                    <btn-theme type="button" @click="navigate">Modifier mes informations</btn-theme>
                </router-link>-->
            </div>
            <div class="liens">
                <router-link class="ligne" to="/commandes">
                    Mes commandes
                </router-link>
                <router-link class="ligne" to="/adresses">
                    Mon carnet d'adresses
                </router-link>
                <btn-theme @click="logout"><v-icon class="mr-2">{{ mdiLogout }}</v-icon>Me déconnecter</btn-theme>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import MonnaieMixin from '../mixins/monnaie';
import BtnTheme from "../components/theme/BtnTheme";
import { mdiTrashCan, mdiLogout } from "@mdi/js";

export default {
    name: "Profil",
    data() {
        return {
            mdiTrashCan,
            mdiLogout
        }
    },
    mixins: [
        MonnaieMixin,
    ],
    components: {
        BtnTheme
    },
    computed: {
        ...mapGetters([
            'user',
            'avoir',
        ]),
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
        }
    }
}
</script>

<style scoped lang="scss">
.profil {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding:20px;
    & > div {
        display:flex;
        flex-direction: column;
        & > button{
            margin: auto auto 0 auto;
        }

        .ligne {
            font: normal normal normal 30px/41px Nunito;
            text-decoration-thickness: 2px;
            text-decoration-color: black;
            margin-bottom:12px;
            color:black;
            padding-left: 1em;
            margin-left: 0;
            margin-right: 0;
        }
    }
}
</style>