<template>
    <div class="compte">
        <div class="entete">
            <div class="fil-ariane">
                <router-link to="/">Accueil</router-link>
                <span> > </span>
                <router-link to="/compte">Mon compte</router-link>
                <span> > </span>
                <router-link to="/compte/suppression">Suppression de compte</router-link>
            </div>
            <h1 class="titre">Suppression de compte</h1>
        </div>
        <div class="carte-arrondie suppression">
            <div class="ligne">
                Afin de confirmer la suppression de votre compte, merci de saisir votre mot de passe.
                <v-text-field :error-messages="error" filled solo label="Mot de passe" class="pass" type="password" v-model="password"></v-text-field>
                <div class="actions">
                    <router-link to="/compte" custom v-slot="{ navigate }">
                        <btn-theme type="button" @click="navigate"><v-icon class="mr-2">{{ mdiArrowLeftBold }}</v-icon>Retour</btn-theme>
                    </router-link>
                    <btn-theme @click="suppression" class="red" type="button"><v-icon class="mr-2">{{ mdiTrashCan }}</v-icon>Effacer mon compte</btn-theme>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Api from "../api/api";
import BtnTheme from "../components/theme/BtnTheme";
import { mdiArrowLeftBold, mdiTrashCan } from "@mdi/js";

export default {
    name: "Suppression",
    components: {
        BtnTheme
    },
    data: () => ({
        suppressionCompteURI: process.env.VUE_APP_API_URL + '/utilisateur/suppression',
        password: '',
        error: [],
        mdiArrowLeftBold,
        mdiTrashCan
    }),
    methods: {
        suppression() {
            this.error = [];
            Api.post(this.suppressionCompteURI, {password: this.password})
                .then(() => {
                    this.$store.dispatch('logout');
                }).catch(err => {
                    this.error = [Api.getMessageFrom(err)];
                })
        }
    }
}
</script>

<style scoped lang="scss">
.suppression {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding:20px;
    max-width:800px;
    & > div {
        display:flex;
        flex-direction: column;
        font: normal normal normal 30px/41px Nunito;
        text-decoration-thickness: 2px;
        text-decoration-color: black;
        margin-bottom:12px;
        color:black;
        padding: 0 1em;
        margin-left: 0;
        margin-right: 0;

        & .pass {
            width:350px;
            border-radius:16px;
            margin: 16px auto;
        }

        & > .actions {
            display: flex;
            flex-direction:row;
            justify-content: space-between;
        }

        & > button{
            margin: auto auto 0 auto;
        }
    }
}
</style>